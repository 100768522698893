<template>
  <div class="entry">
    <div class="pcEntry" v-if="isPC">
      <div
        class="loginBox"
        v-loading="loading"
        :element-loading-text="'系统资源加载中' + percent"
      >
<!--        <div class="left">-->
<!--          <img class="logo" src="../assets/image/baicheng/logo.png" alt="" />-->
<!--          <p>百城计划积极心理品质测试</p>-->
<!--        </div>-->
        <div class="right">
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            class="loginForm"
          >
            <h1>登录</h1>
            <el-form-item prop="account">
              <el-input
                prefix-icon="el-icon-user"
                v-model="loginForm.account"
                maxlength="20"
                placeholder="请输入帐号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-form-item prop="passWord">
                <el-input
                  type="passWord"
                  prefix-icon="el-icon-circle-check"
                  v-model="loginForm.passWord"
                  show-password
                  autocomplete="off"
                  maxlength="20"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-button
                class="loginBtn"
                type="primary"
                @click="submitForm('loginForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="mobileEntry" :style="{ height: bodyHeight + 'px' }" v-else>
      <div class="shadow">
        <img class="logo" src="../assets/image/baicheng/logo.png" alt="" />
        <p class="title">百城计划<br />积极心理品质测试</p>
        <div class="login">
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            class="loginForm"
          >
            <h1>学生登录</h1>
            <el-form-item prop="account">
              <el-input
                @focus="cancelInput"
                prefix-icon="el-icon-user"
                v-model="loginForm.account"
                maxlength="20"
                placeholder="请输入帐号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-form-item prop="passWord">
                <el-input
                  @focus="cancelInput"
                  type="passWord"
                  prefix-icon="el-icon-circle-check"
                  v-model="loginForm.passWord"
                  show-password
                  autocomplete="off"
                  maxlength="20"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-button
                class="loginBtn"
                type="primary"
                @click="submitForm('loginForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from 'js-md5';
import { userLogin, getNextQues } from '@/api/index';
export default {
  data() {
    return {
      bodyHeight: '',
      isPC: true,
      loading: false, //加载状态
      count: 0, //预加载进度
      percent: '', //预加载进度百分比
      loginForm: {
        account: '',
        passWord: '',
      },
      rules: {
        account: [
          {
            required: true,
            message: '请输入帐号',
            trigger: 'blur',
          },
        ],
        passWord: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
      status: true,
      timer: null,
    };
  },
  methods: {
    cancelInput() {
      // document.querySelector('body').scrollTop = 100;
      setTimeout(() => {
        document.querySelector('body').scrollIntoView(false);
      },300)
    },
    //获取进度
    getProgress() {
      let query = { fmUserId: this.$util.getStorage('fmUserId') };
      getNextQues(query)
        .then((res) => {
          if (res.code === 0) {
            //大题编号
            sessionStorage.setItem('fmProductQuestionId', res.data.fmProductQuestionId );
            //小题编号
            sessionStorage.setItem('quesNum', res.data.quesNum);
            //是否完成
            sessionStorage.setItem('isFinished', res.data.isFinished);
            let evalType = JSON.parse(sessionStorage.getItem('login')).evalType;
            // evalType //1.抑郁+熊猫（原来）2.单抑郁3.单熊猫4.普通+抑郁5.普通+熊猫6单普通
            if (res.data.isFinished === 1) {
              //已结束
              if (this.isPC) {
                if(evalType === 1 || evalType=== 3 || evalType === 5) {
                  this.$router.replace('/checkpoint');
                }
                if(evalType === 2 || evalType === 4) {
                  this.$router.replace({
                    path:'/finish',
                    query:{
                      isFinished:res.data.isFinished
                    }
                  });
                }
                if(evalType === 6) {
                  this.$router.replace({
                    path:'/quesFinish',
                    query:{
                      isFinished:res.data.isFinished
                    }
                  });
                }
              } else {
                if(evalType === 1 || evalType === 3 || evalType === 5 || evalType === 6) {
                  this.$router.replace({
                    path: '/mQuesFinish',
                    query:{
                      isFinished:res.data.isFinished
                    }
                  });
                }
                if(evalType === 2 || evalType === 4) {
                  this.$router.replace({
                    path: '/mFinish',
                    query:{
                      isFinished:res.data.isFinished
                    }
                  });
                }
              }
            } else {
              if(this.isPC) {
                if(evalType === 1 || evalType === 3 || evalType === 5) { //有熊猫测评。资源加载
                  this.loading = true;
                  this.preload();
                } else {
                  if(res.data.quesNum === '0-1') {
                    this.$router.replace('/ques');
                  } else if(res.data.quesNum === '0-2') {
                    this.$router.replace('/evaluation');
                  } else {
                    this.$router.replace('/monitor');
                  }
                }
              } else {
                if(res.data.quesNum === '0-1') {
                  this.$router.replace('/mQues');
                } else if(res.data.quesNum === '0-2') {
                  this.$router.replace('/mEvaluation');
                } else {
                  if(evalType === 1) {
                    this.$router.replace({
                      path:'/mFinish',
                      query:{
                        isFinished:res.data.isFinished,
                      }
                    });
                  }
                  if(evalType === 3 || evalType === 5) {
                    this.$router.replace({
                      path:'/mQusFinish',
                      query:{
                        isFinished:res.data.isFinished
                      }
                    });
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query = {
            account: this.loginForm.account,
            passWord: md5(this.loginForm.passWord),
          };
          userLogin(query)
            .then((res) => {
              if (res.code === 0) {
                sessionStorage.setItem('fmUserId', res.data.fmUserId);
                sessionStorage.setItem('account', res.data.account);
                sessionStorage.setItem('name', res.data.name);
                sessionStorage.setItem('token', res.data.token);
                sessionStorage.setItem('login',JSON.stringify(res.data))
                this.getProgress();
                if (this.isPC) {
                  this.$message({
                    message: '登录成功',
                    type: 'success',
                    duration: 1000,
                  });
                }
              }else {
                this.$message.error(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    //图片预加载
    preload() {
      let quesNum = sessionStorage.getItem('quesNum');
      let imgs = [
        require('../assets/image/card/test/cross2red.png'),
        require('../assets/image/card/test/triangle1yellow.png'),
        require('../assets/image/card/test/triangle4green.png'),
        require('../assets/image/card/reaction/top/circle1red.png'),
        require('../assets/image/card/reaction/top/triangle2blue.png'),
        require('../assets/image/card/reaction/top/star3green.png'),
        require('../assets/image/card/reaction/top/cross4yellow.png'),
        require('../assets/image/card/reaction/circle1blue.png'),
        require('../assets/image/card/reaction/circle1green.png'),
        require('../assets/image/card/reaction/circle1yellow.png'),
        require('../assets/image/card/reaction/circle2blue.png'),
        require('../assets/image/card/reaction/circle2green.png'),
        require('../assets/image/card/reaction/circle2red.png'),
        require('../assets/image/card/reaction/circle2yellow.png'),
        require('../assets/image/card/reaction/circle3blue.png'),
        require('../assets/image/card/reaction/circle3green.png'),
        require('../assets/image/card/reaction/circle3red.png'),
        require('../assets/image/card/reaction/circle3yellow.png'),
        require('../assets/image/card/reaction/circle4blue.png'),
        require('../assets/image/card/reaction/circle4green.png'),
        require('../assets/image/card/reaction/circle4red.png'),
        require('../assets/image/card/reaction/circle4yellow.png'),
        require('../assets/image/card/reaction/cross1blue.png'),
        require('../assets/image/card/reaction/cross1green.png'),
        require('../assets/image/card/reaction/cross1red.png'),
        require('../assets/image/card/reaction/cross1yellow.png'),
        require('../assets/image/card/reaction/cross2blue.png'),
        require('../assets/image/card/reaction/cross2green.png'),
        require('../assets/image/card/reaction/cross2red.png'),
        require('../assets/image/card/reaction/cross2yellow.png'),
        require('../assets/image/card/reaction/cross3blue.png'),
        require('../assets/image/card/reaction/cross3green.png'),
        require('../assets/image/card/reaction/cross3red.png'),
        require('../assets/image/card/reaction/cross3yellow.png'),
        require('../assets/image/card/reaction/cross4blue.png'),
        require('../assets/image/card/reaction/cross4green.png'),
        require('../assets/image/card/reaction/cross4red.png'),
        require('../assets/image/card/reaction/star1blue.png'),
        require('../assets/image/card/reaction/star1green.png'),
        require('../assets/image/card/reaction/star1red.png'),
        require('../assets/image/card/reaction/star1yellow.png'),
        require('../assets/image/card/reaction/star2blue.png'),
        require('../assets/image/card/reaction/star2green.png'),
        require('../assets/image/card/reaction/star2red.png'),
        require('../assets/image/card/reaction/star2yellow.png'),
        require('../assets/image/card/reaction/star3blue.png'),
        require('../assets/image/card/reaction/star3red.png'),
        require('../assets/image/card/reaction/star3yellow.png'),
        require('../assets/image/card/reaction/star4blue.png'),
        require('../assets/image/card/reaction/star4green.png'),
        require('../assets/image/card/reaction/star4red.png'),
        require('../assets/image/card/reaction/star4yellow.png'),
        require('../assets/image/card/reaction/triangle1blue.png'),
        require('../assets/image/card/reaction/triangle1green.png'),
        require('../assets/image/card/reaction/triangle1red.png'),
        require('../assets/image/card/reaction/triangle1yellow.png'),
        require('../assets/image/card/reaction/triangle2green.png'),
        require('../assets/image/card/reaction/triangle2red.png'),
        require('../assets/image/card/reaction/triangle2yellow.png'),
        require('../assets/image/card/reaction/triangle3blue.png'),
        require('../assets/image/card/reaction/triangle3green.png'),
        require('../assets/image/card/reaction/triangle3red.png'),
        require('../assets/image/card/reaction/triangle3yellow.png'),
        require('../assets/image/card/reaction/triangle4blue.png'),
        require('../assets/image/card/reaction/triangle4green.png'),
        require('../assets/image/card/reaction/triangle4red.png'),
        require('../assets/image/card/reaction/triangle4yellow.png'),
        require('../assets/image/delay/back.png'),
        require('../assets/image/delay/front.png'),
        require('../assets/image/delay/test/trial1-1.png'),
        require('../assets/image/delay/test/trial1-2.png'),
        require('../assets/image/delay/test/trial1-3.png'),
        require('../assets/image/delay/test/trial1-4.png'),
        require('../assets/image/delay/test/trial2-1.png'),
        require('../assets/image/delay/test/trial2-2.png'),
        require('../assets/image/delay/test/trial2-3.png'),
        require('../assets/image/delay/test/trial2-4.png'),
        require('../assets/image/delay/test/trial3-1.png'),
        require('../assets/image/delay/test/trial3-2.png'),
        require('../assets/image/delay/test/trial3-3.png'),
        require('../assets/image/delay/test/trial3-4.png'),
        require('../assets/image/delay/test/trial4-1.png'),
        require('../assets/image/delay/test/trial4-2.png'),
        require('../assets/image/delay/test/trial4-3.png'),
        require('../assets/image/delay/test/trial4-4.png'),
        require('../assets/image/delay/block1/trial1-1.png'),
        require('../assets/image/delay/block1/trial1-2.png'),
        require('../assets/image/delay/block1/trial1-3.png'),
        require('../assets/image/delay/block1/trial1-4.png'),
        require('../assets/image/delay/block1/trial2-1.png'),
        require('../assets/image/delay/block1/trial2-2.png'),
        require('../assets/image/delay/block1/trial2-3.png'),
        require('../assets/image/delay/block1/trial2-4.png'),
        require('../assets/image/delay/block1/trial3-1.png'),
        require('../assets/image/delay/block1/trial3-2.png'),
        require('../assets/image/delay/block1/trial3-3.png'),
        require('../assets/image/delay/block1/trial3-4.png'),
        require('../assets/image/delay/block1/trial4-1.png'),
        require('../assets/image/delay/block1/trial4-2.png'),
        require('../assets/image/delay/block1/trial4-3.png'),
        require('../assets/image/delay/block1/trial4-4.png'),
        require('../assets/image/delay/block2/trial1-1.png'),
        require('../assets/image/delay/block2/trial1-2.png'),
        require('../assets/image/delay/block2/trial1-3.png'),
        require('../assets/image/delay/block2/trial1-4.png'),
        require('../assets/image/delay/block2/trial2-1.png'),
        require('../assets/image/delay/block2/trial2-2.png'),
        require('../assets/image/delay/block2/trial2-3.png'),
        require('../assets/image/delay/block2/trial2-4.png'),
        require('../assets/image/delay/block2/trial3-1.png'),
        require('../assets/image/delay/block2/trial3-2.png'),
        require('../assets/image/delay/block2/trial3-3.png'),
        require('../assets/image/delay/block2/trial3-4.png'),
        require('../assets/image/delay/block2/trial4-1.png'),
        require('../assets/image/delay/block2/trial4-2.png'),
        require('../assets/image/delay/block2/trial4-3.png'),
        require('../assets/image/delay/block2/trial4-4.png'),
        require('../assets/image/delay/block3/trial1-1.png'),
        require('../assets/image/delay/block3/trial1-2.png'),
        require('../assets/image/delay/block3/trial1-3.png'),
        require('../assets/image/delay/block3/trial1-4.png'),
        require('../assets/image/delay/block3/trial2-1.png'),
        require('../assets/image/delay/block3/trial2-2.png'),
        require('../assets/image/delay/block3/trial2-3.png'),
        require('../assets/image/delay/block3/trial2-4.png'),
        require('../assets/image/delay/block3/trial3-1.png'),
        require('../assets/image/delay/block3/trial3-2.png'),
        require('../assets/image/delay/block3/trial3-3.png'),
        require('../assets/image/delay/block3/trial3-4.png'),
        require('../assets/image/delay/block3/trial4-1.png'),
        require('../assets/image/delay/block3/trial4-2.png'),
        require('../assets/image/delay/block3/trial4-3.png'),
        require('../assets/image/delay/block3/trial4-4.png'),
        require('../assets/image/delay/block4/trial1-1.png'),
        require('../assets/image/delay/block4/trial1-2.png'),
        require('../assets/image/delay/block4/trial1-3.png'),
        require('../assets/image/delay/block4/trial1-4.png'),
        require('../assets/image/delay/block4/trial2-1.png'),
        require('../assets/image/delay/block4/trial2-2.png'),
        require('../assets/image/delay/block4/trial2-3.png'),
        require('../assets/image/delay/block4/trial2-4.png'),
        require('../assets/image/delay/block4/trial3-1.png'),
        require('../assets/image/delay/block4/trial3-2.png'),
        require('../assets/image/delay/block4/trial3-3.png'),
        require('../assets/image/delay/block4/trial3-4.png'),
        require('../assets/image/delay/block4/trial4-1.png'),
        require('../assets/image/delay/block4/trial4-2.png'),
        require('../assets/image/delay/block4/trial4-3.png'),
        require('../assets/image/delay/block4/trial4-4.png'),
        require('../assets/image/delay/block5/trial1-1.png'),
        require('../assets/image/delay/block5/trial1-2.png'),
        require('../assets/image/delay/block5/trial1-3.png'),
        require('../assets/image/delay/block5/trial1-4.png'),
        require('../assets/image/delay/block5/trial2-1.png'),
        require('../assets/image/delay/block5/trial2-2.png'),
        require('../assets/image/delay/block5/trial2-3.png'),
        require('../assets/image/delay/block5/trial2-4.png'),
        require('../assets/image/delay/block5/trial3-1.png'),
        require('../assets/image/delay/block5/trial3-2.png'),
        require('../assets/image/delay/block5/trial3-3.png'),
        require('../assets/image/delay/block5/trial3-4.png'),
        require('../assets/image/delay/block5/trial4-1.png'),
        require('../assets/image/delay/block5/trial4-2.png'),
        require('../assets/image/delay/block5/trial4-3.png'),
        require('../assets/image/delay/block5/trial4-4.png'),
        require('../assets/image/face/keyOption.png'),
        require('../assets/image/face/keyOption_hover.png'),
        require('../assets/image/face/letter.png'),
        require('../assets/image/face/link1.png'),
        require('../assets/image/face/link2.png'),
        require('../assets/image/face/link3.png'),
        require('../assets/image/face/recog_excercise/EX1_recog_happy.jpg'),
        require('../assets/image/face/recog_excercise/EX2_recog_sad.jpg'),
        require('../assets/image/face/recog_excercise/EX3_recog_neutral.jpg'),
        require('../assets/image/face/recognition/A1F6.jpg'),
        require('../assets/image/face/recognition/A1M7.jpg'),
        require('../assets/image/face/recognition/A2F10.jpg'),
        require('../assets/image/face/recognition/A2M6.jpg'),
        require('../assets/image/face/recognition/A3F3.jpg'),
        require('../assets/image/face/recognition/A3M11.jpg'),
        require('../assets/image/face/recognition/D1F7.jpg'),
        require('../assets/image/face/recognition/D1M4.jpg'),
        require('../assets/image/face/recognition/D2F7.jpg'),
        require('../assets/image/face/recognition/D2M8.jpg'),
        require('../assets/image/face/recognition/D3F3.jpg'),
        require('../assets/image/face/recognition/D3M10.jpg'),
        require('../assets/image/face/recognition/F1F10.jpg'),
        require('../assets/image/face/recognition/F1M1.jpg'),
        require('../assets/image/face/recognition/F2F3.jpg'),
        require('../assets/image/face/recognition/F2M7.jpg'),
        require('../assets/image/face/recognition/F3F2.jpg'),
        require('../assets/image/face/recognition/F3M3.jpg'),
        require('../assets/image/face/recognition/H1F2.jpg'),
        require('../assets/image/face/recognition/H1M5.jpg'),
        require('../assets/image/face/recognition/H2F1.jpg'),
        require('../assets/image/face/recognition/H2M1.jpg'),
        require('../assets/image/face/recognition/H3F8.jpg'),
        require('../assets/image/face/recognition/H3M9.jpg'),
        require('../assets/image/face/recognition/N1F10.jpg'),
        require('../assets/image/face/recognition/N1M5.jpg'),
        require('../assets/image/face/recognition/N2F3.jpg'),
        require('../assets/image/face/recognition/N2M10.jpg'),
        require('../assets/image/face/recognition/N3F8.jpg'),
        require('../assets/image/face/recognition/N3M8.jpg'),
        require('../assets/image/face/recognition/S1F9.jpg'),
        require('../assets/image/face/recognition/S1M6.jpg'),
        require('../assets/image/face/recognition/S2F5.jpg'),
        require('../assets/image/face/recognition/S2M3.jpg'),
        require('../assets/image/face/recognition/S3F6.jpg'),
        require('../assets/image/face/recognition/S3M9.jpg'),
        require('../assets/image/face/recognition/SU1F10.jpg'),
        require('../assets/image/face/recognition/SU1M10.jpg'),
        require('../assets/image/face/recognition/SU2F8.jpg'),
        require('../assets/image/face/recognition/SU2M5.jpg'),
        require('../assets/image/face/recognition/SU3F6.jpg'),
        require('../assets/image/face/recognition/SU3M5.jpg'),
        require('../assets/image/face/memory_excercise/EX1_memory_old.jpg'),
        require('../assets/image/face/memory_excercise/EX2_memory_new.jpg'),
        require('../assets/image/face/memory_excercise/EX3_memory_old.jpg'),
        require('../assets/image/face/memory/A1F1_new.jpg'),
        require('../assets/image/face/memory/A1M1_new.jpg'),
        require('../assets/image/face/memory/A2F8_new.jpg'),
        require('../assets/image/face/memory/A2M7_new.jpg'),
        require('../assets/image/face/memory/A3F6_new.jpg'),
        require('../assets/image/face/memory/A3M7_new.jpg'),
        require('../assets/image/face/memory/D1F1_new.jpg'),
        require('../assets/image/face/memory/D1M9_new.jpg'),
        require('../assets/image/face/memory/D2F4_new.jpg'),
        require('../assets/image/face/memory/D2M3_new.jpg'),
        require('../assets/image/face/memory/D3F2_new.jpg'),
        require('../assets/image/face/memory/D3M9_new.jpg'),
        require('../assets/image/face/memory/F1F6_new.jpg'),
        require('../assets/image/face/memory/F1M6_new.jpg'),
        require('../assets/image/face/memory/F2F9_new.jpg'),
        require('../assets/image/face/memory/F2M5_new.jpg'),
        require('../assets/image/face/memory/F3F7_new.jpg'),
        require('../assets/image/face/memory/F3M8_new.jpg'),
        require('../assets/image/face/memory/H1F8_new.jpg'),
        require('../assets/image/face/memory/H1M9_new.jpg'),
        require('../assets/image/face/memory/H2F5_new.jpg'),
        require('../assets/image/face/memory/H2M7_new.jpg'),
        require('../assets/image/face/memory/H3F2_new.jpg'),
        require('../assets/image/face/memory/H3M6_new.jpg'),
        require('../assets/image/face/memory/N1F2_new.jpg'),
        require('../assets/image/face/memory/N1M8_new.jpg'),
        require('../assets/image/face/memory/N2F1_new.jpg'),
        require('../assets/image/face/memory/N2M4_new.jpg'),
        require('../assets/image/face/memory/N3F2_new.jpg'),
        require('../assets/image/face/memory/N3M3_new.jpg'),
        require('../assets/image/face/memory/S1F3_new.jpg'),
        require('../assets/image/face/memory/S1M9_new.jpg'),
        require('../assets/image/face/memory/S2F4_new.jpg'),
        require('../assets/image/face/memory/S2M1_new.jpg'),
        require('../assets/image/face/memory/S3F5_new.jpg'),
        require('../assets/image/face/memory/S3M12_new.jpg'),
        require('../assets/image/face/memory/SU1F6_new.jpg'),
        require('../assets/image/face/memory/SU1M7_new.jpg'),
        require('../assets/image/face/memory/SU2F1_new.jpg'),
        require('../assets/image/face/memory/SU2M3_new.jpg'),
        require('../assets/image/face/memory/SU3F1_new.jpg'),
        require('../assets/image/face/memory/SU3M10_new.jpg'),
        require('../assets/image/other/bg.png'),
        require('../assets/image/other/btn_exit.png'),
        require('../assets/image/other/badge.png'),
        require('../assets/image/other/btn.png'),
        require('../assets/image/other/btn2.png'),
        require('../assets/image/other/cardNumBg.png'),
        require('../assets/image/other/congratulation.png'),
        require('../assets/image/other/error.png'),
        require('../assets/image/other/delayKey.png'),
        require('../assets/image/other/finishTip.png'),
        require('../assets/image/other/focus.png'),
        require('../assets/image/other/hand.png'),
        require('../assets/image/other/img1-1.png'),
        require('../assets/image/other/img2-1.png'),
        require('../assets/image/other/img2-2.png'),
        require('../assets/image/other/img2-3.png'),
        require('../assets/image/other/img3-1.png'),
        require('../assets/image/other/img4-1.png'),
        require('../assets/image/other/img5-1.png'),
        require('../assets/image/other/img5-2.png'),
        require('../assets/image/other/img5-3.png'),
        require('../assets/image/other/panda.png'),
        require('../assets/image/other/jian.png'),
        require('../assets/image/other/ka1-1.png'),
        require('../assets/image/other/ka1-2.png'),
        require('../assets/image/other/ka2-1.png'),
        require('../assets/image/other/ka2-2.png'),
        require('../assets/image/other/ka3-1.png'),
        require('../assets/image/other/ka3-2.png'),
        require('../assets/image/other/ka4-1.png'),
        require('../assets/image/other/ka4-2.png'),
        require('../assets/image/other/ka5-1.png'),
        require('../assets/image/other/ka5-2.png'),
        require('../assets/image/other/key.png'),
        require('../assets/image/other/key_hover.png'),
        require('../assets/image/other/key_hover1.png'),
        require('../assets/image/other/keyboard1.png'),
        require('../assets/image/other/letter.png'),
        require('../assets/image/other/letter_check.png'),
        require('../assets/image/other/loginbg.png'),
        require('../assets/image/other/mouse1.png'),
        require('../assets/image/other/playerBg.png'),
        require('../assets/image/other/practice.png'),
        require('../assets/image/other/practiceEnd.png'),
        require('../assets/image/other/range1.png'),
        require('../assets/image/other/range2.png'),
        require('../assets/image/other/relax.png'),
        require('../assets/image/other/star1.png'),
        require('../assets/image/other/star2.png'),
        require('../assets/image/other/startImg.png'),
        require('../assets/image/other/succeed.png'),
        require('../assets/image/other/tip_bg.png'),
        require('../assets/image/other/title.png'),
        require('../assets/image/other/xu1-1.png'),
        require('../assets/image/other/xu1-2.png'),
        require('../assets/image/other/xu1-3.png'),
        require('../assets/image/other/xu2.png'),
        require('../assets/image/other/xu3.png'),
        require('../assets/image/other/xu4.png'),
        require('../assets/image/other/xu5-1.png'),
        require('../assets/image/other/xu5-2.png'),
        require('../assets/image/other/xu5-3.png'),
        require('../assets/image/other/progressTip.png'),
        require('../assets/image/self/check.png'),
        require('../assets/image/self/left.png'),
        require('../assets/image/self/mirror.png'),
        require('../assets/image/self/right.png'),
        require('../assets/image/self/submit.png'),
        require('../assets/image/self/wordBg1.png'),
        require('../assets/image/self/wordBg2.png'),
      ];
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.count++;
          let percentNum = Math.floor((this.count / imgs.length) * 100);
          this.percent = `${percentNum}%`;
          if (this.count === imgs.length) {
            this.loading = false;
            if(quesNum === '0-1') {
              this.$router.replace('/ques');
            } else if(quesNum=== '0-2') {
              this.$router.replace('/evaluation');
            } else {
              this.$router.replace('/monitor');
            }
          } else {
            if (percentNum >= 90 && this.status) {
              this.status = false;
              this.timer = setTimeout(() => {
                this.loading = false;
                if(quesNum === '0-1') {
                  this.$router.replace('/ques');
                } else if(quesNum === '0-2') {
                  this.$router.replace('/evaluation');
                } else {
                  this.$router.replace('/monitor');
                }
                // this.$router.replace('/monitor');
              }, 2000);
            }
          }
        };
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  created() {
    if (this.$util.isPC()) {
      this.isPC = true;
    } else {
      document.title = '积极心理品质测试';
      this.isPC = false;
    }
  },
  mounted() {
    this.bodyHeight = document.documentElement.clientHeight;
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .pcEntry {
    text-align: center;
    width: 100%;
    min-width: 1000px;
    height: 100vh;
    background-image: url('../assets/image/baicheng/tbg-main.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .loginBox {
      width: 1000px;
      height: 450px;
      display: flex;
      //background: rgba(48, 70, 93, 0.38);

      position: relative;
      margin: 0 auto;
      top: calc((100% - 450px) / 2);
      .left {

        width: 620px;
        text-align: left;
        display: inline-block;
        .logo {
          width: 360px;
          height: 125px;
          margin: 100px 0 20px 0;
        }
        p {
          color: #ffffff;
          font-size: 28px;
          font-weight: 550;
          display: block;
          text-indent: 30px;
        }
      }
      .right {
        border-radius: 30px;
        width: 380px;
        padding: 50px;
        display: inline-block;
        background: #ffffff;
        h1 {
          font-size: 24px;
          color: #689a6a;
          margin-bottom: 50px;
        }
        .loginBtn {
          width: 100%;
          margin-top: 50px;
        }
      }
    }
    ::v-deep .el-form-item__label {
      width: 100%;
      display: block;
      text-align: left;
      color: #289257;
      font-size: 14px;
      line-height: 14px;
      padding: 13px 0 8px 0;
      font-family: 'SYHeiBold';
    }
    ::v-deep .el-input__inner {
      background: #f2f2f2;
    }
    ::v-deep .el-form-item {
      margin-bottom: 30px;
    }
    ::v-deep
      .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      display: none;
    }
  }
}
@media screen and (min-width: 1900px) {
  .pcEntry {
    text-align: center;
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    background-image: url('../assets/image/baicheng/tbg-main.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .loginBox {
      width: 1300px;
      height: 585px;
      display: flex;
      background: rgba(48, 70, 93, 0.38);
      position: relative;
      margin: 0 auto;
      top: calc((100% - 585px) / 2);
      .left {
        width: 806px;
        text-align: left;
        display: inline-block;
        .logo {
          width: 468px;
          height: 175px;
          margin: 130px 0 26px 0;
        }
        p {
          color: #ffffff;
          font-size: 36px;
          font-weight: 550;
          display: block;
          text-indent: 39px;
        }
      }
      .right {
        width: 494px;
        padding: 65px;
        display: inline-block;
        background: #ffffff;
        h1 {
          font-size: 38px;
          color: #689a6a;
          margin-bottom: 65px;
        }
        .loginBtn {
          width: 100%;
          height: 52px;
          font-size: 18px;
          margin-top: 65px;
        }
      }
    }
    ::v-deep .el-form-item__label {
      width: 100%;
      display: block;
      text-align: left;
      color: #289257;
      font-size: 18px;
      line-height: 18px;
      padding: 17px 0 10px 0;
      font-family: 'SYHeiBold';
    }
    ::v-deep .el-input__inner {
      background: #f2f2f2;
      height: 52px;
      font-size: 18px;
    }
    ::v-deep .el-input__icon {
      font-size: 18px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 39px;
      font-size: 18px;
    }
    ::v-deep
      .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      display: none;
    }
  }
}
.mobileEntry {
  width: 100vw;
  height: 100vh;
  background: url('../assets/image/baicheng/m-bg-main.jpg') no-repeat bottom;
  background-size: 100% 100%;
  position: relative;
  .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    .logo {
      width: 12rem;
      margin: 3rem 0 0 1rem;
    }
    .title {
      color: #ffffff;
      font-size: 2rem;
      font-weight: 550;
      margin: 1rem 0 0 2rem;
    }
    .login {
      margin-top: 3rem;
      padding: 3rem;
      text-align: center;
      h1 {
        color: #ffffff;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: left;
      }
      .loginBtn {
        width: 12rem;
        margin-top: 3rem;
        border-radius: 1rem;
      }
      ::v-deep .el-input__inner {
        border-radius: 2rem;
      }
    }
  }
}
</style>
